import AppHeader from './components/header/AppHeader'
import AppFooter from './components/footer/AppFooter'
import ContactUs from './components/contact-us/ContactUs'

import './App.scss'
import BasicWave from './components/animation/basic-wave/BasicWave'
import ingens from './images/4Ing.png'

function App() {
  return (
    <div className="App position-relative">
      <div className="container-fluid bg-app-bg p-0">
        <AppHeader />
        <div className="container-fluid position-relative">
          <div className="content-wrapper">
            <div className="content">
              <h1>iOS and Android apps development</h1>
            </div>
            <div className="preview-wrapper">
              <img src={ingens} />
            </div>
          </div>
          <div className="svg-wrapper">
            <BasicWave />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-secondary position-relative" style={{ zIndex: 4000 }}>
        <ContactUs />
      </div>
      <AppFooter />
    </div>
  )
}

export default App
