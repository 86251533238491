import { useEffect, useState, useReducer, useRef } from 'react'
import AnimationVisibilityScroll from '../animation/visibility-scroll/AnimatiionVisibilityScroll'
import PropTypes from 'prop-types'
import './ContactUs.scss'
import useGetIsSupport from '../../hooks/useGetIsSupport'

const initialData = {
  name: '',
  email: '',
  message: '',
}

function checkEmptyFields(obj) {
  const cb = (val) => val === ''
  return Object.values(obj).some(cb)
}

function checkFields(obj) {
  const cb = (val) => obj[val] !== false
  return Object.keys(obj).filter(cb).length > 0
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const url =
  'https://script.google.com/macros/s/AKfycbx_VZ5j_hGg8997cu-bIQzg5eKtJ9Uw78MFYt2fbqR9YPYL2AOv/exec'

function Toast({ show, text, variant }) {
  const [isShow, setIsShow] = useState(show)

  useEffect(() => {
    setIsShow(show)
  }, [show])

  return (
    <div className={`toast ${isShow ? 'show' : 'showing'} toast-${variant}`} role="alert">
      <div className="toast-header">
        <img src="favicon.ico" />
        <strong>4ingens</strong>
        <strong className="margin-auto"></strong>
        <button type="button" className="btn-close" onClick={() => setIsShow(false)}></button>
      </div>
      <div className="toast-body">{text}</div>
    </div>
  )
}

Toast.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.any,
  variant: PropTypes.oneOf(['success', 'danger']),
}

Toast.defaultProps = {
  show: false,
  variant: 'success',
}

const initReducer = {
  status: null,
  text: '',
}

function reducer(state, action) {
  switch (action.type) {
    case 'success':
      return {
        text: 'Thank you for submitting your application!',
        status: 'success',
      }
    case 'fail':
      return {
        text: 'An error has occurred. Please try again later.',
        status: 'danger',
      }
    case 'drop':
    default:
      return initReducer
  }
}

function ContactUs() {
  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(false)
  const [invalid, setInvalid] = useState({})
  const [state, dispatch] = useReducer(reducer, initReducer)
  const isDisabled = isLoading || checkEmptyFields(data) || checkFields(invalid)
  const node = useRef(null)
  const isSupport = useGetIsSupport()

  useEffect(() => {
    if (state.status) {
      setTimeout(() => dispatch('drop'), 5000)
    }
  }, [state.status])

  useEffect(() => {
    if (isSupport) {
      if (node.current) {
        setTimeout(
          () =>
            node.current.scrollIntoView({
              behavior: 'smooth',
            }),
          500,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (ev) => {
    setData({ ...data, [ev.target.name]: ev.target.value })
  }

  const checkEmail = (ev) => {
    if (data.email && !emailRegex.test(data.email)) {
      setInvalid({ ...invalid, [ev.target.name]: true })
      return
    }
    setInvalid({ ...invalid, [ev.target.name]: false })
  }

  const finish = (res) => {
    dispatch({ type: res })
    setIsLoading(false)
    setData(initialData)
  }

  const onSubmit = (ev) => {
    ev.preventDefault()
    setIsLoading(true)
    const encoded = Object.keys(data)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      })
      .join('&')
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encoded,
    })
      .then((res) => {
        if (res.status >= 400) {
          return Promise.reject('error')
        }
        return res
      })
      .then(() => {
        finish('success')
      })
      .catch(() => {
        finish('fail')
      })
  }

  return (
    <div className="contact-us" id="contact-us" ref={node}>
      <div className="container p-0">
        <AnimationVisibilityScroll controlPoint="bottom" controlPercentage={30} once>
          <h2>Contact us</h2>
        </AnimationVisibilityScroll>
        <div className="container-lg">
          <form className="form" id="sendMail" onSubmit={onSubmit} autoComplete="off">
            <AnimationVisibilityScroll once>
              <label>
                Name<span className="required">*</span>
              </label>
              <input name="name" value={data.name} onChange={onChange} />
            </AnimationVisibilityScroll>
            <AnimationVisibilityScroll className="position-realtive" once>
              <label>
                Email<span className="required">*</span>
              </label>
              <input
                name="email"
                className={invalid?.email ? 'warning' : 'idle'}
                value={data.email}
                onChange={onChange}
                onBlur={checkEmail}
              />
              {invalid?.email && <span>Please, enter valid email</span>}
            </AnimationVisibilityScroll>
            <AnimationVisibilityScroll controlPoint="bottom" controlPercentage={55} once>
              <label>
                Message<span className="required">*</span>
              </label>
              <textarea name="message" rows={3} value={data.message} onChange={onChange} />
              <div className="flex-center">
                <button type="submit" disabled={isDisabled}>
                  {isLoading ? '...Loading' : 'Submit'}
                </button>
              </div>
            </AnimationVisibilityScroll>
          </form>
        </div>
        <div className="toast-c-container">
          {state.status && <Toast show={!!state.status} text={state.text} variant={state.status} />}
        </div>
      </div>
    </div>
  )
}

export default ContactUs
