import './AppFooter.scss'

function getYears() {
  const startYear = 2022
  const date = new Date()
  const currentYear = date.getFullYear()
  return startYear === currentYear ? startYear : `${startYear} - ${currentYear}`
}

function AppFooter() {
  return (
    <footer className="App-Footer">
      <div className="container flex-center" id="contacts">
        <div className="block-column">
          <h4>Address</h4>
          <p>Katolicka porta 6, Novi Sad, Serbia</p>
        </div>
        <div className="centered-flex">
          <div className="block-column">
            <h4>Email</h4>
            <a href="mailto:info@4ingens.com">info@4ingens.com</a>
          </div>
        </div>
        <div className="block-column">
          <h4>Phone</h4>
          <a href="tel:+381616458216">+3 816 16 45 82 16</a>
        </div>
      </div>
      <div className="container">
        <div className="flex-center">
          <p className="text-small">&copy; {getYears()} 4ingens - All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default AppFooter
