import { useRef, useEffect, useState } from 'react'
import './AnimationVisibilityScroll.scss'
import { useVisible } from '../../../hooks/useVisible'
import PropTypes from 'prop-types'

function AnimationVisibilityScroll({ children, controlPoint, controlPercentage, className, once }) {
  const root = useRef(null)
  const [isShown, setIsShown] = useState(false)
  const isVisible = useVisible(root, controlPoint, controlPercentage)

  useEffect(() => {
    if (!isShown && isVisible && once) {
      setIsShown(true)
    }
  }, [isVisible, isShown, once])

  return (
    <div ref={root} className={className}>
      <div
        className={`${
          isShown
            ? 'anim-visible anim-static'
            : isVisible
            ? 'anim-visible anim-visible-appear'
            : 'anim-visible'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

AnimationVisibilityScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.node])),
  ]).isRequired,
  controlPoint: PropTypes.oneOf(['top', 'bottom', null]),
  controlPercentage: PropTypes.number,
  className: PropTypes.string,
  once: PropTypes.bool,
}

AnimationVisibilityScroll.defaultProps = {
  controlPoint: null,
  controlPercentage: null,
}

export default AnimationVisibilityScroll
