import './BasicWave.scss'

function BasicWave() {
  return (
    <div className="svg-anim-wrapper">
      <div className="svg-wave-wrapper wave" />
      <div className="svg-wave-wrapper wave-2" />
      <div className="svg-wave-wrapper wave-3" />
    </div>
  )
}

export default BasicWave
