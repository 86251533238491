import { useState, useCallback, useEffect } from 'react'

export function useVisible(ref, pointYKey, controlPercentage = 100) {
  const [isVisible, setIsVisible] = useState(false)

  const handleChangeVisible = useCallback(() => {
    if (ref.current) {
      const documentTop = window.scrollY
      const documentBottom = documentTop + window.innerHeight
      const { top, height } = ref.current.getBoundingClientRect()
      const elemTop = window.scrollY + top
      const elemBottom = elemTop + height
      const controlPoint = controlPercentage && elemTop + height * (controlPercentage / 100)
      const isControlPoinIntersect =
        controlPoint &&
        ((pointYKey === 'top' && controlPoint >= documentTop) ||
          (pointYKey === 'bottom' && controlPoint <= documentBottom))
      const isPointIntersect =
        (pointYKey === 'top' && elemTop >= documentTop) ||
        (pointYKey === 'bottom' && elemBottom <= documentBottom)
      const resultCondition =
        isControlPoinIntersect ||
        isPointIntersect ||
        (elemBottom <= documentBottom && elemTop >= documentTop)
      if (resultCondition) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
  }, [ref, pointYKey, controlPercentage])

  useEffect(() => {
    handleChangeVisible()
    window.addEventListener('scroll', handleChangeVisible)
    return () => window.removeEventListener('scroll', handleChangeVisible)
  }, [handleChangeVisible])

  return isVisible
}
