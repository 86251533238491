import './AppHeader.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { ReactComponent as Logo } from '../../images/ђҐбгаб 176.svg'

function AppHeader() {
  return (
    <header className="container-fluid">
      <nav className="App-nav">
        <div className="logo">
          <a href="/">
            <Logo />
          </a>
        </div>
        <ul>
          <li>
            <AnchorLink href="#contact-us">Contact us</AnchorLink>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default AppHeader
